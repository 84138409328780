import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { UserStackParamList } from '../../routes/user';
import { isCoordinatorSelector, isMasterSelector } from '../../store/selectors/auth';

type NavigationProp = StackNavigationProp<UserStackParamList>;

export default function AddRecordsButton() {
  const [open, setOpen] = useState(false);
  const onStateChange = (state: { open: boolean }) => setOpen(state.open);
  const navigation = useNavigation<NavigationProp>();
  const isMaster = useSelector(isMasterSelector);
  const isCoordinator = useSelector(isCoordinatorSelector);

  const actions = useMemo(() => {
    let newActions = [
      {
        icon: 'file-sign',
        label: 'Ordem de Serviço',
        onPress: () => navigation.navigate('NewServiceOrder'),
      },
    ];

    if (isMaster) {
      newActions = [
        {
          icon: 'account-group',
          label: 'Coordenador',
          onPress: () => navigation.navigate('NewCoordinator'),
        },
        {
          icon: 'account-group',
          label: 'Consultor',
          onPress: () => navigation.navigate('NewConsultant'),
        },
        {
          icon: 'account-tie',
          label: 'Cliente',
          onPress: () => navigation.navigate('NewCustomer'),
        },
        {
          icon: 'briefcase',
          label: 'Projeto',
          onPress: () => navigation.navigate('NewProject'),
        },
        {
          icon: 'file-document-outline',
          label: 'Fatura',
          onPress: () => navigation.navigate('NewInvoice'),
        },
      ];
    }
    return newActions;
  }, [isMaster]);

  if (isCoordinator) return null;

  return (
    <FAB.Group
      open={open}
      visible
      icon={open ? 'minus' : 'plus'}
      actions={actions}
      onStateChange={onStateChange}
    />
  );
}
