import { Dimensions } from 'react-native';

const spacing = {
  xxsmall: 2,
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 24,
  xlarge: 32,
};

const window = Dimensions.get('window');

export { spacing, window };
