import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const authSelector = (state: RootState) => state.auth;

export const userSelector = createSelector(authSelector, auth => auth.user);

export const tokenSelector = createSelector(authSelector, auth => auth.jwt);

export const authenticatedSelector = createSelector(
  authSelector,
  auth => !!auth.user.id && !!auth.jwt
);

export const userTypeSelector = createSelector(authSelector, auth => auth.userType);

export const isMasterSelector = createSelector(userTypeSelector, userType => userType === 'master');

export const isConsultantSelector = createSelector(
  userTypeSelector,
  userType => userType === 'consultant'
);

export const isCoordinatorSelector = createSelector(
  userTypeSelector,
  userType => userType === 'coordinator'
);

export const companySelector = createSelector(authSelector, auth => auth.company);

export const companyIdSelector = createSelector(authSelector, auth => auth.company.id);

export const isDarkModeSelector = createSelector(authSelector, auth => !!auth.darkMode);
